/*
 * @Author: your name
 * @Date: 2021-09-22 23:19:38
 * @LastEditTime: 2022-01-08 11:26:35
 * @LastEditors: linlianghao
 * @Description: In User Settings Edit
 */
import { getAllVideo } from '@/services/material';
import { getDriveUsage, receiveDriveUsage, removeReceiveDriveUsage } from '@/utils/ipcRenderer';

export interface MaterialCache {
  id: string,
  campusChannelId: string,
  materialName: string,
  materialContent: string,
  updateTime: string | undefined
}

interface Identification {
  identification: string,
  updateTime: string | undefined
}

// 资源存放路径
const MATERIAL_ROOT_PATH = "C:\\MxcDownload";
// 一定时间不操作进行静默下载
const TIME_FLAG = 3 * 60 * 1000;
// 禁止下载的页面
const CAN_NOT_DOWNLOAD_PATH = [
  "/customer/material", // 素材页
]
// 硬盘占用超过一定范围就不做缓存了
const DRIVE_USAGE_LIMIT = 0.9;

class MaterialService {
  static instance: MaterialService | null = null;
  // 视频素材列表
  videoList: MaterialCache[] = [];
  // 设备硬盘使用情况
  driveUsage: number = 0;
  // 防抖定时器
  timeInterval: NodeJS.Timeout = setTimeout(()=>{}, 0);
  // 写入流
  writeStream: any = null;
  // 是否终止下载
  isStop: boolean = true;

  static getInstance() {
    if (!this.instance) {
      this.instance = new MaterialService();
    }
    return this.instance;
  }

  init = async() => {
    try {
      console.log("[MaterialService] init MaterialService");
      // 获取视频素材列表
      this.getAllMaterialVideo();

      // 判断是否存在需要使到用的工具（通过Electron传入）
      if (!window.path || !window.fs || !window.request) {
        console.error("[MaterialService] Missing Tools Path、fs、request");
        return;
      }
      
      // 获取磁盘剩余的空间
      receiveDriveUsage(this.setDriveUsage)
      getDriveUsage();

      // 创建根目录
      const hasRootPath = await this.checkRootPath();
      if (!hasRootPath) return;

      // 监听document的点击事件
      document.addEventListener("click", this.handleClick);

      // 先主动触发一次
      this.handleClick();
    } catch (err) {
      console.error("[MaterialService] init Error", err)
    }
  }

  uninit = () => {
    console.log("[MaterialService] uninit MaterialService")
    document.removeEventListener("click", this.handleClick);
    removeReceiveDriveUsage(this.setDriveUsage)
    this.stopDownload();
  }

  /** 获取本地缓存 */
  getLocalCache = (item: MaterialCache) => {
    const { materialContent} = item;
    const filePath = this.getFilePath(item);
    let result: string = materialContent;

    return new Promise((resolve) => {
			window.fs.stat(filePath, (err: any, res: any) => {
        console.log("[MaterialService] Get Local Cache", res, err);
        
        // 若不报错则找到该文件
				if (!err) result = filePath;

				resolve(result);
			})
		})
  }

  /** 获取视频素材列表 */
  getAllMaterialVideo = async() => {
    try {
      if (this.videoList.length === 0) {
        const {data} = await getAllVideo();
        this.videoList = data;
      }
    } catch (err) {
      console.error("[MaterialService] Get All Material Video", err)
    }
  }

  /** 处理点击事件 */
  handleClick = () => {
    // 防抖，一定时间用户不操作才下载，防止影响占用带宽
    clearTimeout(this.timeInterval);
    this.timeInterval = setTimeout(this.start, TIME_FLAG);
    this.stopDownload();
  }

  /** 获取到设备硬盘的使用情况 */
  setDriveUsage = (e: any, driveUsage: number) => {
    console.log("[MaterialService] DriveUsage ", driveUsage);
    this.driveUsage = driveUsage;
  }

  /** 开始执行下载操作 */
  start = async() => {
    console.log("[MaterialService] Start Material Service ", this.videoList);
    // 在某些页面禁止下载
    const canDownload = this.getCanDownload();
    if (!canDownload) return;

    // 先清理掉非最新文件和非已完成的文件
    this.checkDelFile();
    
    // 硬盘空间是否不足
    const isMaxDriveUsage = this.driveUsage > DRIVE_USAGE_LIMIT;
    if (isMaxDriveUsage) {
      console.log("[MaterialService] DriveUsage is Max，Can Not Download", this.driveUsage);
      return;
    }

    this.isStop = false;
  
    for (let i = 0; i < this.videoList.length; i ++) {
      const item = this.videoList[i];

      const hasExist = await this.checkDownload(item) as boolean;

      if (!hasExist && !this.isStop) {
        await this.startDownload(item);
      }
    }
  }

  /** 判断是否已下载 */
  checkDownload = (item: MaterialCache) => {
    const filePath = this.getFilePath(item);

    return new Promise((resolve) => {
			window.fs.stat(filePath, (err: any, res: any) => {        
        // 若不报错则找到该文件
				if (!err) resolve(true);

				resolve(false);
			})
		})
  }

  /** 开始下载 */
  startDownload = async(item: MaterialCache) => {
    const { materialContent} = item;
    const filePath = this.getFilePath(item, true);
    console.log("[MaterialService] Start Download ", filePath);

    return new Promise((resolve) => {
			this.writeStream = window.fs.createWriteStream(filePath);
			this.writeStream.on('error', (err: any) => {
					console.log('[MaterialService] Download Error', err);
					resolve(false);
			})
			this.writeStream.on('finish', () => {
          if (!this.isStop) {
            console.log('[MaterialService] Download Finish', filePath);
            this.finishDownload(filePath);
            resolve(true);
          }
			})
			
			window.request(materialContent).pipe(this.writeStream)
		})
  }

  /** 终止下载 */
  stopDownload = () => {
    this.isStop = true;
    if (this.writeStream) {
      console.log("[MaterialService] Stop Download");
      this.writeStream.end();
      this.writeStream = null;
    }
  }

  /** 下载完成（修改名字，打上finish的标记） */
  finishDownload = (filePath: string) => {
    const newFilPath = `${filePath.split(".")[0]}-finish.mp4`;
    window.fs.rename(filePath, newFilPath, (err: any) => {
      if (err) { console.error("[MaterialService] Rename Err", err) }
    });
  }

  /** 
   * 获取文件路径
   * @param  { MaterialCache } item  文件对象
   * @param  { boolean } isOrigin  是否源文件（源文件没有finish后缀）
   */
  getFilePath = (item: MaterialCache, isOrigin: boolean = false) => {
    const { id, campusChannelId, materialName, updateTime } = item;
    const fileName = `${campusChannelId}-${id}-${materialName}-${updateTime}${isOrigin ? "": "-finish"}`;
    const filePath = window.path.resolve(MATERIAL_ROOT_PATH, `${fileName}.mp4`);
    return filePath;
  }

  /** 清理非最新文件和非已完成的文件 */
  checkDelFile = () => {
    // 通过远端获取的视频素材列表，由（频道id+id）的形式创建素材唯一值
    let onLineFileList: Identification[] = [];
    this.videoList.forEach((item: MaterialCache) => {
      const { id, campusChannelId, updateTime } = item;
      const identification = `${campusChannelId}-${id}`;
      onLineFileList.push({ identification, updateTime });
    })

    // 获取素材文件夹下的所有文件，找到与远端一致的文件后，判断更新时间和是否完成
    const files = window.fs.readdirSync(MATERIAL_ROOT_PATH);
    files.forEach((file: any) => {
      const fileName = file.split(".")[0];
      const fileParameter = fileName.split("-");
      const campusChannelId = fileParameter[0];
      const id = fileParameter[1];
      const updateTime = fileParameter[3];
      const isFinish = fileParameter[4];

      const curIdentification = `${campusChannelId}-${id}`;

      const fileTemp = onLineFileList.find((item: Identification) => item.identification === curIdentification);

      if (fileTemp) {
        // 若更新时间不一致，则说明该文件更新，把旧的删除
        if (fileTemp.updateTime && updateTime !== fileTemp.updateTime) {
          this.delFile(file);
          return;
        }
        // 若没有finish的标记，则说明该未完整下载，做删除处理
        if (!isFinish) {
          this.delFile(file);
          return;
        }
      } else {
        // 若本地存在某个视频文件，在接口返回的列表中不存在，则删除这个文件
        if (onLineFileList.length > 0) {
          // 接口返回的列表长度不为0的情况下（防止偶尔接口请求失败，将全部文件删除了）
          this.delFile(file);
          return;
        }
      }
    })
  }

  /** 删除指定文件 */
  delFile = (file: string) => {
    try {
      const filePath = window.path.resolve(MATERIAL_ROOT_PATH, file);
      console.log('[MaterialService] Del File', filePath)
      window.fs.unlinkSync(filePath);
    } catch (err) {
      console.error("[MaterialService] Del File Error", err)
    }
  }

  /** 创建根目录文件夹 */
  checkRootPath = () => {
    return new Promise((resolve) => {
      window.fs.access(MATERIAL_ROOT_PATH, (err: any)=>{
        if (err) {
          console.log('[MaterialService] No Root Path ', err);
          console.log('[MaterialService] New Root Path ');
          // 没有目录，尝试创建目录
          window.fs.mkdir(MATERIAL_ROOT_PATH, (mkdirError: any) => {
            if (mkdirError) {
              console.log('[MaterialService] New Root Path Error', mkdirError);
              resolve(false);
            } else {
              console.log('[MaterialService] New Root Path Success');
              resolve(true);
            }
          });
        } else {
          resolve(true);
        }
      })
    })
  }

  /** 判断此页面是否允许做下载动作 */
  getCanDownload = () => {
    const hash = window.location.hash;
    console.log("[MaterialService] Current History is ", hash);
    let canDownload = true;
    CAN_NOT_DOWNLOAD_PATH.forEach((item: string) => {
      if (hash.includes(item)) {
        canDownload = false;
        console.log("[MaterialService] Current History Can Not Download");
      }
    })
    return canDownload;
  }
}

export default MaterialService.getInstance();