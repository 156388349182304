/*
 * @Author: linlianghao
 * @Date: 2021-08-12 21:45:40
 * @LastEditors: linlianghao
 * @LastEditTime: 2022-01-11 11:10:09
 * @Description:
 */
import { getCoursePackageList } from "@/services/material";

export default {
  namespace: 'coursePackage',
  state: {
    coursePackageList: []
  },
  effects: {
    * getCoursePackageList({ payload }: any, { call, put }: any): any {
      try {
        const { data } = yield call(getCoursePackageList);
        yield put({
          type: '_setCoursePackageList',
          payload: data
        });
      } catch (err) {
        console.error("[CoursePackage] Get CoursePackage List Error", err);
      }
    },
  },
  reducers: {
    _setCoursePackageList (state: any, { payload }: any) {
      return {
        ...state,
        coursePackageList: payload,
      };
    }
  },
};
