import * as Api from '../services/index';

export default {
  namespace: 'registration',
  state: {
    channelList: [],
    gradeList: [],
    baseForm: {},
    contentForm: {},
    newContent: [],
    countData: {},
    reportInfo: {
      abilityDimension: [],
      knowledgePointsGrade: [],
    }
  },

  effects: {
    // 试听课报告信息
    * getDemoUserLessonReport({ payload, callback }: any, { call, put }: any): any {
      const res = yield call(Api.getDemoUserLessonReport, payload);
      yield put({
        type: '_setReportInfo',
        payload: { reportInfo: res.data }

      });
      return res.data
    },

    // 获取表单基本信息
    * getBaseInfo({ payload, callback }: any, { call, put }: any): any {
      const res = yield call(Api.getBaseInfo, payload);
      console.log('getBaseInfo', res);
      let { channels, grades } = res.data;
      channels = channels.map((v: any) => {
        return {
          label: v.name,
          value: v.code
        };
      });
      grades = grades.map((v: any) => {
        return {
          label: v.itemName,
          value: v.itemValue
        };
      });
      yield put({
        type: '_changeState',
        payload: {
          channelList: [channels],
          gradeList: [grades]
        }
      });
      callback && callback();
    },
    // 保存leads信息
    * save({ payload, callback }: any, { call, put }: any): any {
      const res = yield call(Api.save, payload);
      console.log('save', res);
      callback && callback(res);
    },
    // 保存问卷信息
    * saveQuestions({ payload, callback }: any, { call, put }: any): any {
      const res = yield call(Api.saveQuestions, payload);
      console.log('saveQuestions', res);
      callback && callback(res);
    },
    // 获取调查问卷信息
    * getWalkInfo({ payload, callback }: any, { call, put }: any): any {
      const newContent = [];
      const res = yield call(Api.getWalkInfo, payload);
      const data = res.data;
      const contentForm = JSON.parse(data.content);
      delete data.content;
      const baseForm = data;
      console.log('getWalkInfo', data);

      for (const i in contentForm) {
        if (/.*[\u4e00-\u9fa5]+.*$/.test(i)) {
          newContent.push({
            label: i,
            value: contentForm[i]
          });
        }
      }

      yield put({
        type: '_changeState',
        payload: {
          baseForm,
          contentForm,
          newContent
        }
      });

      callback && callback(res);
    },
    // 统计用户有无信息接口
    * getCount({ payload }: any, { call, put }: any): any {
      const res = yield call(Api.getCount, payload);
      console.log('getCount', res);
      yield put({
        type: '_changeState',
        payload: {
          countData: res.data
        }
      });
      return res.data;
      // callback && callback(res.data)
    },
    // 判断leads归属
    * checkLeads({ payload, callback }: any, { call, put }: any): any {
      try {
        const res = yield call(Api.checkLeads, payload);
        console.log('checkLeads', res);
        callback && callback(res);
      } catch (err) {
        callback && callback(err);
      }
    },
    // 检查手机号是否存在
    * checkPhone({ payload, callback }: any, { call, put }: any): any {
      try {
        const res = yield call(Api.checkPhone, payload);
        console.log('checkPhone', res);
        callback && callback(false);
      } catch (err) {
        callback && callback(true);
      }
    },
    // 获取试听课报告
    * getDemoPreviewUrl({ payload, callback }: any, { call, put }: any): any {
      try {
        const res = yield call(Api.getDemoPreviewUrl, payload);
        console.log('getDemoPreviewUrl', res);
        callback && callback(res);
      } catch (err) {
        callback && callback(err);
      }
    },
    // 获取试听课报告
    * getByUserId({ payload }: any, { call, put }: any): any {
      // try {
      const res = yield call(Api.getByUserId, payload);
      return res;
      // console.log('getByUserId', res);
      // callback && callback(res);
      // } catch (err) {
      //   callback && callback(err);
      // }
    // * getByUserId({ payload, callback }: any, { call, put }: any): any {
    //   try {
    //     const res = yield call(Api.getByUserId, payload);
    //     console.log('getByUserId', res);
    //     callback && callback(res);
    //   } catch (err) {
    //     callback && callback(err);
    //   }
      // * getByUserId({ payload, callback }: any, { call, put }: any): any {
      //   try {
      //     const res = yield call(Api.getByUserId, payload);
      //     console.log('getByUserId', res);
      //     callback && callback(res);
      //   } catch (err) {
      //     callback && callback(err);
      //   }
    },

    // 获取等级测评结果
    * getTestResult({ payload, callback }: any, { call, put }: any): any {
      try {
        const res = yield call(Api.getTestResult, payload);
        callback && callback(res);
      } catch (err) {
        callback && callback(err);
      }
    },

    // 获取等级测评结果
    * getTestResultRecord({ payload, callback }: any, { call, put }: any): any {
      try {
        const res = yield call(Api.getTestResultRecord, payload);
        callback && callback(res);
      } catch (err) {
        callback && callback(err);
      }
    }
  },

  reducers: {
    _changeState(state: any, { payload }: any) {
      return {
        ...state,
        ...payload
      };
    },
    _setReportInfo(state: any, { payload }: any) {
      const data = {
        ...state,
        ...payload
      };
      data.reportInfo.abilityDimension = JSON.parse(payload.reportInfo.abilityDimension),
      data.reportInfo.knowledgePointsGrade = JSON.parse(payload.reportInfo.knowledgePointsGrade);
      // data.reportInfo.lessonResultUrl = 'https://sit.miaocode.com/studyworks/python/trialclass/'
      console.log('试听课数据', data.reportInfo);
      return data;
    }

  }
};
