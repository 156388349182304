/*
 * @Author: your name
 * @Date: 2021-09-22 23:19:38
 * @LastEditTime: 2022-03-09 15:38:32
 * @LastEditors: liusuyun
 * @Description: In User Settings Edit
 */
import request from '@/utils/request';

/**
 * 查询未签约leads列表
 */
export async function listAllUnsignedLeads(params: any) {
  return request('/crm-market/leads/listAllUnsignedLeads', {
    method: 'POST',
    data: params
  });
}

/**
 * 根据leadsID查询手机号
 */
export async function getPhone(params: any) {
  return request('/crm-market/leads/getPhone', {
    method: 'GET',
    data: params
  });
}

/**
 * 获取leads详情
 */
export async function details(params: any) {
  return request('/crm-market/leads/details', {
    method: 'GET',
    data: params
  });
}

/**
 * 修改leads信息
 */
export async function updateLeads(params: any) {
  return request('/crm-market/leads/updateLeads', {
    method: 'POST',
    data: params
  });
}

/**
 * 修改leads信息
 * ps: 这里为了降低后端代码的耦合度，单独一个新的接口出来，不直接使用 /crm-market/leads/updateLeads 更新部分字段，比如：微信号字段
 */
export async function updateUserById(params: any) {
  return request('/crm-user/user/updateUserById', {
    method: 'GET',
    data: params
  });
}

/**
 *  国内所有城市 + 国外只到国家
 */
export async function listAllCity(params: any) {
  return request('/crm-account/city/listAllCity', {
    method: 'GET',
    data: params
  });
}

/**
 * 获取leads的流转记录
 */
export async function getLeadsCirculation(params: any) {
  return request('/crm-market/leadsLog/getLeadsCirculation', {
    method: 'GET',
    data: params
  });
}

/**
 * 获取leads的跟进记录
 */
export async function getFollowRecord(params: any) {
  return request('/crm-market/leadsRemark/getFollowRecord', {
    method: 'GET',
    data: params
  });
}

/**
 * 获取leads转介绍关系
 */
export async function getInvitedUsersVO(params: any) {
  return request('/crm-market/leads/getInvitedUsersVO', {
    method: 'GET',
    data: params
  });
}

/**
 * 获取正式课老师回访的内容
 */
export async function formalCallbackHistoryDetail(params: any) {
  return request('/studentPageOfTeacher/formalCallbackHistoryDetail', {
    method: 'GET',
    data: params,
    prefix: API_URL_V2
  });
}

/**
 * 获取老师反馈
 */
export async function getDemoFeedBackInfo(params: any) {
  return request('/course/getDemoFeedBackInfo', {
    data: params,
    prefix: API_URL_V2
  });
}

/**
 * 添加备注（详情页 - 记录 - 提交记录（备注））
 */
export async function addRemark(params: any) {
  return request('/crm-market/leadsRemark/addRemark', {
    method: 'POST',
    data: params
  });
}

/**
 * 更新下次跟进时间
 */
export async function updateNextFollowTime(params: any) {
  return request('/crm-market/cc/updateNextFollowTime', {
    method: 'GET',
    data: params
  });
}

/**
 * 获取调查问卷详情(记录 - 用户登记信息)
 */
export async function getLeadsQuestionnaire(params: any) {
  return request('/crm-market/leadsRemark/getLeadsQuestionnaire', {
    method: 'GET',
    data: params
  });
}

/**
 * 获取试听课报告
 */
export async function getUserLessonId(params: any) {
  return request('/course/getUserLessonId', {
    method: 'GET',
    data: params,
    prefix: API_URL_V2
  });
}

/**
 * 获取试听课学习报告链接
 */
export async function getDemoPreviewUrl(params: any) {
  return request('/crm-teaching/leadsBooking/getDemoPreviewUrl', {
    method: 'GET',
    data: params
  });
}

/**
 * 根据 userId 查询当前记录有没有直播间，进行监课
 */
export async function getDemoRoom(params: any) {
  return request('/crm-teaching/lessonRoom/supervise', {
    method: 'POST',
    data: params,
    requestType: 'form'
  });
}

/**
 * 获取leads跟进次数(详情页 - 流转记录 - 跟进次数统计)
 */
export async function getLeadsFollowCount(params: any) {
  return request('/crm-market/leadsLog/getLeadsFollowCount', {
    method: 'GET',
    data: params
  });
}

/**
 * 公海列表查询
 */
export async function listPublicSea(params: any) {
  return request('/crm-market/leads/workbenchListPublicSea', {
    method: 'POST',
    data: params
  });
}

/**
 * 新建leads
 */
export async function addLeads(params: any) {
  return request('/crm-market/leads/addLeads', {
    method: 'POST',
    data: params
  });
}

/**
 * leads领取/批量领取
 */
export async function pickLeads(params: any) {
  return request('/crm-market/leads/pickLeads', {
    method: 'POST',
    data: params
  });
}

/**
 * 修改leads特别关心以及好友关系
 */
export async function updateLeadsOtherInfo(params: any) {
  return request('/crm-market/leads/updateLeadsOtherInfo', {
    method: 'POST',
    data: params
  });
}

/**
 * 检查leads是否流转
 */
export async function doCheckLeads(params: any) {
  return request('/crm-market/ccTodayTask/doCheckLeads', {
    method: 'GET',
    data: params
  });
}

/**
 *  获取商品信息用于收款
 */
export async function getGoods(params: any) {
  return request('/crm-order/goods/getGoods', {
    method: 'GET',
    data: params
  });
}

/**
 *  获取商品信息用于收款
 */
export async function getCouponList(params: any) {
  return request('/crm-order/userCoupon/listUserGoodsCoupon', {
    method: 'GET',
    data: params
  });
}

/**
 *  根据商品id获取对应的抵扣规则
 */
export async function getExchangeRuleByGoodsId(params: any) {
  return request('/integration/getExchangeRuleByGoodsId', {
    method: 'GET',
    data: params,
    prefix: API_URL_V2
  });
}

/**
 *  根据leadsid获取userId
 */
export async function getUserId(params: any) {
  return request('/crm-user/user/getUserInfo', {
    method: 'GET',
    data: params
  });
}

/**
 *  获取支付链接
 */
export async function getPayUrl(params: any) {
  return request('/crm-order/goodsOrder/getPayUrl', {
    method: 'GET',
    data: params
  });
}

// 获取用户设备检测信息
export async function getForceDeviceCheckDetails(params: any) {
  return request('/forceDeviceCheck/getForceDeviceCheckDetails', {
    method: 'GET',
    data: params,
    prefix: API_URL_V2
  });
}

// 获取所有销售人员
export async function getCcList(params: any) {
  return request('/crm-account/account/cc/list', {
    method: 'GET',
    data: params
  });
}

// 获取所有在职推广人员
export async function getPusherList(params: any) {
  return request('/crm-account/account/pusher/list', {
    method: 'GET',
    data: params
  });
}

// 查看用户可用海报
export async function getCcGenPoster(params: any) {
  return request('/crm-teaching/posterInfo/getCcGenPoster', {
    method: 'GET',
    data: params
  });
}

// 生成海报
export async function generatePoster(params: any) {
  return request('/crm-teaching/posterInfo/generatePoster', {
    method: 'GET',
    data: params
  });
}

/**
 * 解锁、锁定leads
 */
 export async function lockOrUnlock(params: any) {
  return request('/crm-market/leadsExtension/lockOrUnlock', {
    method: 'POST',
    data: params,
  });
}

export async function saveWhiteLeads(params: any) {
  return request('/crm-market/leadsWhiteList/saveWhiteLeads', {
    method: 'POST',
    data: {
      ...params,
    },
  });
}
